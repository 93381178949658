  
import axiosClient from './axiosClient';
import { serviceBase } from './serviceBase';

export const getGiftCardsService = serviceBase({
	request: () => axiosClient.get('gift-card')
});

export const getCategoriesService = serviceBase({
	request: () => axiosClient.get('categories')
});

export default getGiftCardsService;