  
import { GiftCardsActionCreators } from '../reducers/giftCardReducer';
import { SearchTextActionCreators} from '../reducers/searchTextReducer'
import {
	getGiftCardsService,
	getCategoriesService
} from '../services/giftCards';
// import * as MessageActions from 'app/store/actions/fuse/message.actions';

export const getGiftCards = data => async dispatch => {
	dispatch(GiftCardsActionCreators.requestGiftCards());
	try {
		const response = await getGiftCardsService();
		dispatch(
			GiftCardsActionCreators.successfulGiftCards({
				result: response
			})
		);
	} catch (e) {
		// dispatch(MessageActions.showMessage({ message: e.message, variant: 'error' }));
		dispatch(GiftCardsActionCreators.errorGiftCards(e));
	}
};

export const getCategories = () => async (dispatch) => {
	dispatch(GiftCardsActionCreators.requestCategories());
	try {
	  const response = await getCategoriesService();
	  dispatch(
		GiftCardsActionCreators.successfulCategories({
		  result: response,
		})
	  );
	} catch (e) {
	  // dispatch(MessageActions.showMessage({ message: e.message, variant: 'error' }));
	  dispatch(GiftCardsActionCreators.errorCategories(e));
	}
  };

export const setGiftCardsSearchText = (value, history) => (dispatch) => {
	dispatch(SearchTextActionCreators.setGiftCardsSearchText({ searchText: value }));
	if(history.location.pathname !== '/shop'){
	  history.push("/shop");
	}
  };