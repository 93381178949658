
import { createActions, createReducer } from "reduxsauce";

const { Types, Creators } = createActions({
  requestGiftCards: [],
  successfulGiftCards: ["payload"],
  errorGiftCards: ["error"],
  requestCategories: [],
  successfulCategories: ["payload"],
  errorCategories: ["error"],
});

export const GiftCardsActionCreators = Creators;

const initialState = {
  loading: false,
  giftCards: [],
  error: "",
  selectedGiftCard: {},
  searchText: "",
  message: "",
  categories: [],
};

const requestGiftCards = (state = initialState) => ({
  ...state,
  loading: true,
  error: "",
  giftCards: [],
});

const  sortFunc = (a,b) => {
  const { catA } = a.categories && a.categories[0].name;
  const { catB } = b.categories[0].name;
  if(a.name < b.name ){
    return -1;
  }
  if(a.name  > b.name ){
    return 1;
  }
  return 0

}

function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
       const key = keyGetter(item);
       const collection = map.get(key);
       if (!collection) {
           map.set(key, [item]);
       } else {
           collection.push(item);
       }
  });
  let ar = [];
  map.forEach((value, key, map) => {
    ar = ar.concat(value)
  })
  return ar;
}

const successfulGiftCards = (state = initialState, action) => {
  const { message, giftCards } = action.payload.result;
  return {
    ...state,
    loading: false,
    message,
    giftCards: giftCards,
    // giftCards: []
  };
};

const errorGiftCards = (state = initialState, action) => ({
  ...state,
  loading: false,
  error: action.error,
  giftCards: null,
});

const requestCategories = (state = initialState) => ({
  ...state,
  loading: true,
  error: "",
  categories: [],
});

const successfulCategories = (state = initialState, action) => {
  const { message, categories } = action.payload.result;
  return {
    ...state,
    loading: false,
    message,
    categories: categories,
  };
};


const errorCategories = (state = initialState, action) => ({
  ...state,
  loading: false,
  error: action.error,
  categories: null,
});

export default createReducer(initialState, {
  [Types.REQUEST_GIFT_CARDS]: requestGiftCards,
  [Types.SUCCESSFUL_GIFT_CARDS]: successfulGiftCards,
  [Types.ERROR_GIFT_CARDS]: errorGiftCards,
  [Types.REQUEST_CATEGORIES]: requestCategories,
  [Types.SUCCESSFUL_CATEGORIES]: successfulCategories,
  [Types.ERROR_CATEGORIES]: errorCategories,
});